import {
  Uil500px,
  UilAirplay,
  // UilArrowGrowth,
  UilAt,
  UilBagAlt,
  UilUserPlus,
  UilBookAlt,
  UilBookOpen,
  UilBookReader,
  UilCalendarAlt,
  UilChartBar,
  UilChat,
  UilCheckSquare,
  UilCircle,
  UilClipboardAlt,
  UilClock,
  UilCompactDisc,
  UilCreateDashboard,
  UilDatabase,
  UilDocumentLayoutLeft,
  UilEdit,
  UilEnvelope,
  UilBuilding,
  UilExchange,
  UilExclamationOctagon,
  // UilExpandArrowsAlt,
  UilFile,
  UilFileShieldAlt,
  UilHeadphones,
  UilIcons,
  UilImages,
  UilLayerGroup,
  UilMap,
  UilPresentation,
  UilQuestionCircle,
  UilSearch,
  UilServer,
  UilSetting,
  UilShoppingCart,
  UilSquareFull,
  UilTable,
  UilUsdCircle,
  UilUsersAlt,
  UilWindowSection,
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import { NavTitle } from './Style';
// import versions from '../demoData/changelog.json';
import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
import {
  SUBE_MUDURU,
  BAKANLIK_DAIRE_BASKANI,
  KAMU_MUHENDISI,
  IL_SUBE_MUDURU,
  IL_MUHENDISI,
  ADMIN,
} from '../constants/roleConstant';
import { ADMIN as T_ADMIN, FIRMA as T_FIRMA, KURUM as T_KURUM } from '../constants/typeConstant';
import { useAuth } from '../hooks/useAuth';

function MenuItems({ toggleCollapsed }) {
  const { hasRoles, hasTypes } = useAuth();
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : '';
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };
  const changeNavbar = (topMode) => {
    const html = document.querySelector('html');
    if (topMode) {
      html.classList.add('ninjadash-topmenu');
    } else {
      html.classList.remove('ninjadash-topmenu');
    }
    dispatch(changeMenuMode(topMode));
  };
  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
    } else {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  const darkmodeActivated = () => {
    document.body.classList.add('dark-mode');
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove('dark-mode');
  };

  const items = [
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('yonetim')}</NavTitle>,
      'yonetim-title',
      null,
      null,
      'group',
    ),
    getItem(t('kullaniciYonetimi'), 'kullaniciYonetimi', !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/kullanicilar/kullanicilist/`}>
          {t('kullanicilar')}
        </NavLink>,
        'kullanicilar',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/kullanicilar/kullanicigruplari/`}>
          {t('kullaniciGruplari')}
        </NavLink>,
        'kullaniciGruplari',
        null,
      ),
    ]),
    getItem(t('firmakurumislemleri'), 'firmaYonetimi', !topMenu && <UilBuilding />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/firmaislemleri/firmalar/`}>
          {t('firmalarkurumlar')}
        </NavLink>,
        'firmalar',
        null,
      ),
    ]),

    hasTypes([T_ADMIN, T_FIRMA, T_KURUM]) &&
      getItem(t('muhendisIslemleri'), 'muhendisYonetimi', !topMenu && <UilBuilding />, [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/muhendisislemleri/muhendisler/`}>
            {t('muhendisler')}
          </NavLink>,
          'mühendisler',
          null,
        ),
      ]),

    hasRoles([ADMIN]) &&
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/kullanicilar/kurumkullanicilist`}>
          {t('kurumKullanicilari')}
        </NavLink>,
        'kurumKullanicilari',
        !topMenu && <UilUsersAlt />,
      ),

    hasRoles([SUBE_MUDURU, BAKANLIK_DAIRE_BASKANI, KAMU_MUHENDISI]) &&
      hasRoles([SUBE_MUDURU, BAKANLIK_DAIRE_BASKANI, KAMU_MUHENDISI, IL_MUHENDISI, IL_SUBE_MUDURU]) &&
      getItem(t('tanimlar'), 'tanimlar', !topMenu && <UilSetting />, [
        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/tanimlar/formasyon/`}>
            {t('formasyon')}
          </NavLink>,
          'formasyon',
          null,
        ),

        getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/tanimlar/litoloji/`}>
            {t('litoloji')}
          </NavLink>,
          'litoloji',
          null,
        ),
      ]),
    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('proje')}</NavTitle>,
      'proje-title',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/wizards/proje`}>
        {t('projeOlustur')}
      </NavLink>,
      'projeOlustur',
      !topMenu && <UilBagAlt />,
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/proje/projelist/`}>
        {t('projeler')}
      </NavLink>,
      'projeler',
      !topMenu && <UilBagAlt />,
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/proje/works/`}>
        Proje Çalışmalarım Temp
      </NavLink>,
      'projelertemp',
      !topMenu && <UilBagAlt />,
    ),

    getItem(
      !topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t('firmakurummenuleri')}</NavTitle>,
      'kul-title',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to="">
        {t('info.profile')}
      </NavLink>,
      'kul-kulbilgileri',
      !topMenu && <UilBagAlt />,
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to="">
        {t('muhendisBilgileri')}
      </NavLink>,
      'kul-muhendisbilgileri',
      !topMenu && <UilBagAlt />,
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to="">
        {t('proje')}
      </NavLink>,
      'kul-projeler',
      !topMenu && <UilBagAlt />,
    ),
  ];

  return (
    <Menu
      subMenuCloseDelay={1}
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
