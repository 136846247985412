const actions = {
  SINGLE_KULLANICILAR_BEGIN: 'SINGLE_KULLANICILAR_BEGIN',
  SINGLE_KULLANICILAR_SUCCESS: 'SINGLE_KULLANICILAR_SUCCESS',
  SINGLE_KULLANICILAR_ERR: 'SINGLE_KULLANICILAR_ERR',

  FILTER_KULLANICILAR_BEGIN: 'FILTER_KULLANICILAR_BEGIN',
  FILTER_KULLANICILAR_SUCCESS: 'FILTER_KULLANICILAR_SUCCESS',
  FILTER_KULLANICILAR_ERR: 'FILTER_KULLANICILAR_ERR',
  FILTER_SET_FIELDS: 'FILTER_SET_FIELDS',

  SORTING_KULLANICILAR_BEGIN: 'SORTING_KULLANICILAR_BEGIN',
  SORTING_KULLANICILAR_SUCCESS: 'SORTING_KULLANICILAR_SUCCESS',
  SORTING_KULLANICILAR_ERR: 'SORTING_KULLANICILAR_ERR',

  KULLANICILAR_READ_BEGIN: 'KULLANICILAR_READ_BEGIN',
  KULLANICILAR_READ_SUCCESS: 'KULLANICILAR_READ_SUCCESS',
  KULLANICILAR_READ_ERR: 'KULLANICILAR_READ_ERR',

  UPDATE_PAGINATION: 'UPDATE_PAGINATION',

  UPDATE_FILTERS: 'UPDATE_FILTERS',

  UPDATE_DATA: 'UPDATE_DATA',

  updateData: (val) => {
    return {
      type: actions.UPDATE_DATA,
      updateData: val,
    };
  },

  updateFilters: (filters) => {
    return {
      type: actions.UPDATE_FILTERS,
      filters,
    };
  },

  updatePagination: (pagination) => {
    return {
      type: actions.UPDATE_PAGINATION,
      pagination,
    };
  },

  kullanicilarReadBegin: () => {
    return {
      type: actions.KULLANICILAR_READ_BEGIN,
    };
  },

  kullanicilarReadSuccess: (data) => {
    return {
      type: actions.KULLANICILAR_READ_SUCCESS,
      data,
    };
  },

  kullanicilarReadErr: (err) => {
    return {
      type: actions.KULLANICILAR_READ_ERR,
      err,
    };
  },

  singleKullanicilarBegin: () => {
    return {
      type: actions.SINGLE_KULLANICILAR_BEGIN,
    };
  },

  singleKullanicilarSuccess: (data) => {
    return {
      type: actions.SINGLE_KULLANICILAR_SUCCESS,
      data,
    };
  },

  singleKullanicilarErr: (err) => {
    return {
      type: actions.SINGLE_KULLANICILAR_ERR,
      err,
    };
  },

  filterKullanicilarBegin: () => {
    return {
      type: actions.FILTER_KULLANICILAR_BEGIN,
    };
  },

  filterKullanicilarSuccess: (data) => {
    return {
      type: actions.FILTER_KULLANICILAR_SUCCESS,
      data,
    };
  },

  filterKullanicilarErr: (err) => {
    return {
      type: actions.FILTER_KULLANICILAR_ERR,
      err,
    };
  },

  filterSetFields: (filterFields) => {
    return {
      type: actions.FILTER_SET_FIELDS,
      filterFields,
    };
  },

  sortingKullanicilarBegin: () => {
    return {
      type: actions.SORTING_KULLANICILAR_BEGIN,
    };
  },

  sortingKullanicilarSuccess: (data) => {
    return {
      type: actions.SORTING_KULLANICILAR_SUCCESS,
      data,
    };
  },

  sortingKullanicilarErr: (err) => {
    return {
      type: actions.SORTING_KULLANICILAR_ERR,
      err,
    };
  },
};

export default actions;
