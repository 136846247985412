export const MUHENDIS = 7;
export const FIRMA = 8;
export const ADMIN = 10;
export const SUBE_MUDURU = 11;
export const KAMU_MUHENDISI = 12;
export const JEOLOJI_MUHENDISI = 14;
export const JEOFIZIK_MUHENDISI = 15;
export const CEVRE_VE_SEHIRCILIK_MUHENDISI = 19; // KONTROL MUHENDISI
export const KOMISYON = 100;
export const IL_MUHENDISI = 101;
export const ILLER_BANKASI = 102;
export const ILBANK = 103;
export const KOMISYON_UYESI = 104;
export const KOMISYON_YEDEK_UYESI = 105;
export const UYGULAMA_YONETICISI = 106; // ADMIN
export const IL_SUBE_MUDURU = 107;
export const BAKANLIK_DAIRE_BASKANI = 108;
export const DUYURU_YETKILISI = 109;
export const FIRMA_ONAY_YETKILI = 110;
export const PROJE_ALANI_KILITLEME_YETKILI = 111;
export const KURUM_KULLANICI_DUZENLEME_YETKILI = 112;
export const PROJE_DURUMU_DEGISTIRME = 118;
export const JEOLOJI_VE_JEOFIZIK_MUHENDISI = 119;
