import PropTypes, { object } from 'prop-types';
import React from 'react';
import { ModalStyled } from './styled';
import { Button } from '../buttons/buttons';
import { load } from 'ol/Image';

function Modal(props) {
  const {
    bodyStyle,
    destroyOnClose,
    onCancel,
    className,
    onOk,
    visible,
    title,
    type,
    color,
    maskClosable,
    footer,
    width,
    children,
    centered,
    style,
    loading,
    cancelText,
    okText,
  } = props;

  return (
    <ModalStyled
      bodyStyle={bodyStyle}
      destroyOnClose={destroyOnClose}
      confirmLoading={loading}
      style={style}
      centered={centered}
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      type={color ? type : false}
      width={width}
      className={className}
      maskClosable={maskClosable}
      footer={
        footer || footer === null
          ? footer
          : [
              <Button type="dashed" key="back" onClick={onCancel}>
                {cancelText}
              </Button>,
              <Button type={type} key="submit" onClick={onOk}>
                {okText}
              </Button>,
            ]
      }
    >
      {children}
    </ModalStyled>
  );
}

Modal.defaultProps = {
  width: 620,
  className: 'atbd-modal',
};

Modal.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  destroyOnClose: PropTypes.bool,
  visible: PropTypes.bool,
  centered: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  maskClosable: PropTypes.bool,
  type: PropTypes.string,
  okText: PropTypes.string,
  bodyStyle: PropTypes.string,
  cancelText: PropTypes.string,
  style: PropTypes.object,
  footer: PropTypes.arrayOf(object),
  width: PropTypes.number,
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

const alertModal = ModalStyled;
export { Modal, alertModal };
