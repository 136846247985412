import propTypes from 'prop-types';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import * as roles from '../../constants/roleConstant';

function ProtectedRoute({ Component, path }) {
  const { isAuthenticated, hasRoles } = useAuth();

  return isAuthenticated && (hasRoles([]) || true) ? (
    <Routes>
      {' '}
      <Route element={<Component />} path={path} />{' '}
    </Routes>
  ) : (
    <Routes>
      {' '}
      <Route path="/ninjadash-react/admin" element={<Navigate to="/" />} />
    </Routes>
  );
}

ProtectedRoute.propTypes = {
  Component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
