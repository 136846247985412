import actions from './actions';

const {
  SINGLE_KULLANICILAR_BEGIN,
  SINGLE_KULLANICILAR_SUCCESS,
  SINGLE_KULLANICILAR_ERR,

  FILTER_KULLANICILAR_BEGIN,
  FILTER_KULLANICILAR_SUCCESS,
  FILTER_KULLANICILAR_ERR,
  FILTER_SET_FIELDS,

  SORTING_KULLANICILAR_BEGIN,
  SORTING_KULLANICILAR_SUCCESS,
  SORTING_KULLANICILAR_ERR,

  KULLANICILAR_READ_BEGIN,
  KULLANICILAR_READ_SUCCESS,
  KULLANICILAR_READ_ERR,

  UPDATE_PAGINATION,

  UPDATE_FILTERS,

  UPDATE_DATA,
} = actions;

const initialStateFilter = {
  data: [],
  loading: false,
  error: null,
  filterFields: {},
  pagination: {
    current: 1,
    pageSize: 10,
  },
  filters: {},
  updateData: false,
};

const Reducer = (state = initialStateFilter, action) => {
  const { type, data, err, filterFields, pagination, filters, updateData } = action;
  switch (type) {
    case UPDATE_DATA:
      return {
        ...initialStateFilter,
        updateData,
      };
    case UPDATE_FILTERS:
      return {
        ...initialStateFilter,
        filters,
      };
    case UPDATE_PAGINATION:
      return {
        ...initialStateFilter,
        pagination,
      };
    case FILTER_KULLANICILAR_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case FILTER_KULLANICILAR_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case FILTER_KULLANICILAR_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case FILTER_SET_FIELDS:
      return {
        ...initialStateFilter,
        filterFields,
      };
    case SORTING_KULLANICILAR_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case SORTING_KULLANICILAR_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case SORTING_KULLANICILAR_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case KULLANICILAR_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case KULLANICILAR_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };
    case KULLANICILAR_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const SingleReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_KULLANICILAR_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SINGLE_KULLANICILAR_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case SINGLE_KULLANICILAR_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { SingleReducer, Reducer };
