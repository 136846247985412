import PropTypes from 'prop-types';
import { useAuth } from '../../hooks/useAuth';

function Can({ perform, children }) {
  const { hasRoles } = useAuth();

  if (!hasRoles(perform)) {
    return null;
  }

  return children;
}

function TypeCan({ types, children }) {
  const { hasTypes } = useAuth();

  if (!hasTypes(types)) {
    return null;
  }

  return children;
}

Can.propTypes = {
  perform: PropTypes.arrayOf(PropTypes.number).isRequired,
  children: PropTypes.node.isRequired,
};

TypeCan.propTypes = {
  types: PropTypes.arrayOf(PropTypes.number).isRequired,
  children: PropTypes.node.isRequired,
};

export default Can;
export { TypeCan };
