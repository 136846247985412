import { Spin } from 'antd';
import React, { Suspense } from 'react';
//  import { AuthenticationWrap } from './overview/style';
//  import Heading from '../../../components/heading/heading';

const ProjeLayout = (WraperContent) => {
  return function () {
    return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <WraperContent />

        {/* <AuthenticationWrap style={{ backgroundImage: `url("${require('../../../static/img/admin-bg-light.png')}")` }}>
          <div className="ninjadash-authentication-wrap">
            <div className="ninjadash-authentication-brand">
              <img src={require(`../../../static/img/logo/yerbis_logo_green_giris.png`)} alt="" />
              <figcaption style={{ 'margin-top': '12px' }}>
                <Heading className="" as="h2">
                  YER BİLİMSEL ETÜT BİLGİ SİSTEMİ
                </Heading>
                <span className="user-designation">San Francisco, CA</span>
              </figcaption>
            </div>
            <WraperContent />
          </div>
        </AuthenticationWrap> */}
      </Suspense>
    );
  };
};

export default ProjeLayout;
