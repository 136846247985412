class YerbisConstants {
  static TanimTurTurKodlari = {
    YerlesimeUygunluk: 7,
  };

  static EtudTur = {
    None: 0,
    PlanTuruBelirsizJeolojikEtud: 10,
    AraziKullaniminaEsasJeolojikEtud: 11,
    JeolojikVeJeoteknikEtud: 12,
    MikrobolgelemeEtud: 13,
    JeolojikEtud: 479,
  };

  static EtudTurDesc = {
    [YerbisConstants.EtudTur.PlanTuruBelirsizJeolojikEtud]: 'Plan Türü Belirsiz Jeolojik Etüt',
    [YerbisConstants.EtudTur.AraziKullaniminaEsasJeolojikEtud]: 'Arazi Kullanımına Esas Jeolojik Etüt',
    [YerbisConstants.EtudTur.JeolojikVeJeoteknikEtud]: 'Jeolojik-Jeoteknik Etüt',
    [YerbisConstants.EtudTur.MikrobolgelemeEtud]: 'Mikrobölgeleme Etüt',
    [YerbisConstants.EtudTur.JeolojikEtud]: 'Jeolojik Etüt',
  };

  static PlanTur = {
    AraziKullaniminaEsas: 7,
    ImarPlaninaEsas: 8,
  };

  static PlanTurDesc = {
    [YerbisConstants.PlanTur.AraziKullaniminaEsas]: 'Arazi Kullanimina Esas',
    [YerbisConstants.PlanTur.ImarPlaninaEsas]: 'İmar Planina Esas',
  };

  static FirmaOnay = {
    Onaysiz: 0,
    Onayli: 1,
  };

  static FirmaOnayDesc = {
    [YerbisConstants.FirmaOnay.Onaysiz]: 'Onaysız',
    [YerbisConstants.FirmaOnay.Onayli]: 'Onaylı',
  };

  static HNBKayitDurum = {
    Aktif: 1,
    Pasif: 3,
  };

  static HNBKayitDurumDesc = {
    [YerbisConstants.HNBKayitDurum.Aktif]: 'Aktif',
    [YerbisConstants.HNBKayitDurum.Pasif]: 'Pasif',
  };

  static YerbisKayitDurum = {
    Pasif: 0,
    Aktif: 1,
  };

  static YerbisKayitDurumDesc = {
    [YerbisConstants.YerbisKayitDurum.Aktif]: 'Aktif',
    [YerbisConstants.YerbisKayitDurum.Pasif]: 'Pasif',
  };

  static MuhendisUnvan = {
    None: 0,
    JeofizikMuhendisi: 129,
    JeolojiMuhendisi: 130,
    Diger: 563,
    JeolojiVeJeofizikMuhendisi: 576,
  };

  static MuhendisUnvanDesc = {
    [YerbisConstants.MuhendisUnvan.None]: 'Belirsiz',
    [YerbisConstants.MuhendisUnvan.JeofizikMuhendisi]: 'Jeofizik Mühendisi',
    [YerbisConstants.MuhendisUnvan.JeolojiMuhendisi]: 'Jeoloji Mühendisi',
    [YerbisConstants.MuhendisUnvan.Diger]: 'Diğer',
    [YerbisConstants.MuhendisUnvan.JeolojiVeJeofizikMuhendisi]: 'Jeoloji ve Jeofizik Mühendisi',
  };

  static ArsivProjeleri = {
    Arsiv2018Oncesi: 1,
    Arsiv2018Sonrasi: 2,
  };

  static ArsivProjeleriDesc = {
    [YerbisConstants.ArsivProjeleri.Arsiv2018Oncesi]: '2018 Öncesi Arşiv',
    [YerbisConstants.ArsivProjeleri.Arsiv2018Sonrasi]: '2018 Sonrası Arşiv',
  };

  static MuhendisTip = {
    None: 0,
    FirmaVeyaKurum: 131,
    Bakanlik: 132,
  };

  static MuhendisTipDesc = {
    [YerbisConstants.MuhendisTip.None]: 'Belirsiz',
    [YerbisConstants.MuhendisTip.FirmaVeyaKurum]: 'Firma/Kurum',
    [YerbisConstants.MuhendisTip.Bakanlik]: 'Bakanlık',
  };

  static MuhendisFilterTip = {
    None: 0,
    FirmaKurum: 1,
    IlMudurluk: 2,
    GenelMudurluk: 3,
  };

  static MuhendisFilterTipDesc = {
    [YerbisConstants.MuhendisFilterTip.None]: 'Tümü',
    [YerbisConstants.MuhendisFilterTip.FirmaKurum]: 'Firma/Kurum',
    [YerbisConstants.MuhendisFilterTip.IlMudurluk]: 'İl Müdürlüğü',
    [YerbisConstants.MuhendisFilterTip.GenelMudurluk]: 'Genel Müdürlük',
  };

  static ProjePaftaTurleri = {
    None: 0,
    ITRF_1000: 557,
    ITRF_25000: 558,
    ITRF_5000: 559,
    ED50_1000: 572,
    ED50_25000: 573,
    ED50_5000: 574,
  };

  static ProjePaftaTurleriDesc = {
    [YerbisConstants.ProjePaftaTurleri.None]: 'Tanımsız',
    [YerbisConstants.ProjePaftaTurleri.ITRF_1000]: "Raster 1000'lik",
    [YerbisConstants.ProjePaftaTurleri.ITRF_25000]: "Raster 25000'lik",
    [YerbisConstants.ProjePaftaTurleri.ITRF_5000]: "Raster 5000'lik",
    [YerbisConstants.ProjePaftaTurleri.ED50_1000]: "ED50 Raster 1000'lik",
    [YerbisConstants.ProjePaftaTurleri.ED50_25000]: "ED50 Raster 25000'lik",
    [YerbisConstants.ProjePaftaTurleri.ED50_5000]: "ED50 Raster 5000'lik",
  };

  static ProjeDurumKodlari = {
    Taslak: 191,
    Hazirlik: 46,
    Basvuru: 47,
    BasvuruRed: 48,
    OnEtut: 51,
    CalismaIptal: 95,
    AraziVeBuroCalismalari: 52,
    ProjeBeklemeyeAlindi: 94,
    Komisyonda: 93,
    RaporOnayIslemiTamamlandi: 57,
    Arsiv: 251,

    // None: 0,
    // OnDegerlendirme: 49,
    // OnDegerlendirmeUygun: 50,
    // KurumKontrol: 53,
    // KurumKontrolVeFirmaDuzenleme: 54,
    // RaporOnayAsamasinda: 55,
    // RaporOnayaHazir: 56,
    // RaporOnayIslemiTamamla: 157,
  };

  static ProjeDurumKodlariDesc = {
    [YerbisConstants.ProjeDurumKodlari.Taslak]: 'Taslak',
    [YerbisConstants.ProjeDurumKodlari.Hazirlik]: 'Hazırlık',
    [YerbisConstants.ProjeDurumKodlari.Basvuru]: 'Başvuru',
    [YerbisConstants.ProjeDurumKodlari.BasvuruRed]: 'Başvuru Red',
    [YerbisConstants.ProjeDurumKodlari.OnEtut]: 'Ön Etüt',
    [YerbisConstants.ProjeDurumKodlari.CalismaIptal]: 'Çalışma İptal',
    [YerbisConstants.ProjeDurumKodlari.AraziVeBuroCalismalari]: 'Arazi ve Büro Çalışmaları',
    [YerbisConstants.ProjeDurumKodlari.ProjeBeklemeyeAlindi]: 'Proje Beklemeye Alındı',
    [YerbisConstants.ProjeDurumKodlari.Komisyonda]: 'Komisyonda',
    [YerbisConstants.ProjeDurumKodlari.RaporOnayIslemiTamamlandi]: 'Rapor Onay İşlemi Tamamlandı',
    [YerbisConstants.ProjeDurumKodlari.Arsiv]: 'Arşiv',

    // [YerbisConstants.ProjeDurumKodlari.None]: 'None',
    // [YerbisConstants.ProjeDurumKodlari.OnDegerlendirme]: 'Ön Değerlendirme',
    // [YerbisConstants.ProjeDurumKodlari.OnDegerlendirmeUygun]: 'Ön Değerlendirme Uygun',
    // [YerbisConstants.ProjeDurumKodlari.KurumKontrol]: 'Kurum Kontrol',
    // [YerbisConstants.ProjeDurumKodlari.KurumKontrolVeFirmaDuzenleme]: 'Kurum Kontrol ve Firma Düzenleme',
    // [YerbisConstants.ProjeDurumKodlari.RaporOnayAsamasinda]: 'Rapor Onay Aşamasında',
    // [YerbisConstants.ProjeDurumKodlari.RaporOnayaHazir]: 'Rapor Onaya Hazır',
    // [YerbisConstants.ProjeDurumKodlari.RaporOnayIslemiTamamla]: 'Rapor Onay İşlemi Tamamla',
    // [YerbisConstants.ProjeDurumKodlari.Onaylandi]: 'Onaylandı',
  };

  static CalismaTurleri = {
    None: 0,
    Stabilite: 1,
    Sondaj: 2,
    ArastirmaCukuru: 3,
    Des: 4,
    Ip: 5,
    Csamt: 6,
    Masw: 7,
    MaswKirilma: 8,
    Mikrotremor: 9,
    SismikKirilma: 10,
    SismikYansima: 11,
    SismikTomografi: 12,
    KuyuSismik: 13,
    Gravite: 14,
    Manyetik: 15,
    Sp: 16,
    GozlemNoktasi: 17,
    Remi: 18,
    Vlf: 19,
    ManyetoTellurik: 20,
    Tem: 21,
    Hendek: 22,
    YerRadari: 23,
    Ert: 24,
    PaleosismolojiCalismasi: 25,
    Fay: 26,
    YerlesimeUygunluk: 27,
    Egim: 28,
    JeolojiHaritasi: 29,
    MuhendislikHaritasi: 30,
    CalismaAlaniGenislet: 31,
  };

  static CalismaTurleriKisaltma = {
    Masw: 'MASW',
    MaswKirilma: 'MASW KIRILMA',
    SismikTomografi: 'SIST',
    SismikKirilma: 'SIS',
    SismikYansima: 'SISY',
    Ip: 'IP',
    Sondaj: 'SK',
    ArastirmaCukuru: 'AC',
    GozlemNoktasi: 'GN',
    KuyuSismik: 'KUYUSIS',
    Remi: 'REMI',
    Mikrotremor: 'M',
    Des: 'DES',
    Ert: 'ERT',
  };

  static CalismaTurleriDesc = {
    [YerbisConstants.CalismaTurleri.None]: 'Belirsiz',
    [YerbisConstants.CalismaTurleri.Stabilite]: 'Stabilite',
    [YerbisConstants.CalismaTurleri.Sondaj]: 'Sondaj',
    [YerbisConstants.CalismaTurleri.ArastirmaCukuru]: 'Araştırma Çukuru',
    [YerbisConstants.CalismaTurleri.Des]: 'DES',
    [YerbisConstants.CalismaTurleri.Ip]: 'IP',
    [YerbisConstants.CalismaTurleri.Csamt]: 'CSAMT',
    [YerbisConstants.CalismaTurleri.Masw]: 'MASW',
    [YerbisConstants.CalismaTurleri.MaswKirilma]: 'MASW Kırılma',
    [YerbisConstants.CalismaTurleri.Mikrotremor]: 'Mikrotremor',
    [YerbisConstants.CalismaTurleri.SismikKirilma]: 'Sismik Kırılma',
    [YerbisConstants.CalismaTurleri.SismikYansima]: 'Sismik Yansıma',
    [YerbisConstants.CalismaTurleri.SismikTomografi]: 'Sismik Tomografi',
    [YerbisConstants.CalismaTurleri.KuyuSismik]: 'Kuyu Sismiği',
    [YerbisConstants.CalismaTurleri.Gravite]: 'Gravite',
    [YerbisConstants.CalismaTurleri.Manyetik]: 'Manyetik',
    [YerbisConstants.CalismaTurleri.Sp]: 'SP',
    [YerbisConstants.CalismaTurleri.GozlemNoktasi]: 'Gözlem Noktası',
    [YerbisConstants.CalismaTurleri.Remi]: 'REMI',
    [YerbisConstants.CalismaTurleri.Vlf]: 'VLF',
    [YerbisConstants.CalismaTurleri.ManyetoTellurik]: 'Manyeto Tellürik',
    [YerbisConstants.CalismaTurleri.Tem]: 'TEM',
    [YerbisConstants.CalismaTurleri.Hendek]: 'Hendek',
    [YerbisConstants.CalismaTurleri.YerRadari]: 'Yer Radarı',
    [YerbisConstants.CalismaTurleri.Ert]: 'ERT',
    [YerbisConstants.CalismaTurleri.PaleosismolojiCalismasi]: 'Paleosismoloji Çalışması Başlat',
    [YerbisConstants.CalismaTurleri.Fay]: 'Jeoloji Veri Kriterleri ile Belirlenen Diğer Faylar',
    [YerbisConstants.CalismaTurleri.YerlesimeUygunluk]: 'Yerleşime Uygunluk',
    [YerbisConstants.CalismaTurleri.Egim]: 'Eğim',
    [YerbisConstants.CalismaTurleri.JeolojiHaritasi]: 'Jeoloji Haritası',
    [YerbisConstants.CalismaTurleri.MuhendislikHaritasi]: 'Mühendislik Haritası',
    [YerbisConstants.CalismaTurleri.CalismaAlaniGenislet]: 'Çalışma Alanı Genişlet',
  };

  static CalismaCizimTurleri = {
    // 1:line, 2:point, 3:multiline, 4:rectangle, 5:polygon,
    [YerbisConstants.CalismaTurleri.Stabilite]: 1,
    [YerbisConstants.CalismaTurleri.Sondaj]: 2,
    [YerbisConstants.CalismaTurleri.ArastirmaCukuru]: 2,
    [YerbisConstants.CalismaTurleri.Des]: 2,
    [YerbisConstants.CalismaTurleri.Ip]: 2,
    [YerbisConstants.CalismaTurleri.Csamt]: 1,
    [YerbisConstants.CalismaTurleri.Masw]: 1,
    [YerbisConstants.CalismaTurleri.MaswKirilma]: 1,
    [YerbisConstants.CalismaTurleri.Mikrotremor]: 2,
    [YerbisConstants.CalismaTurleri.SismikKirilma]: 1,
    [YerbisConstants.CalismaTurleri.SismikYansima]: 1,
    [YerbisConstants.CalismaTurleri.SismikTomografi]: 1,
    [YerbisConstants.CalismaTurleri.KuyuSismik]: 2,
    [YerbisConstants.CalismaTurleri.Gravite]: '3,4,5',
    [YerbisConstants.CalismaTurleri.Manyetik]: 3,
    [YerbisConstants.CalismaTurleri.Sp]: 3,
    [YerbisConstants.CalismaTurleri.GozlemNoktasi]: 2,
    [YerbisConstants.CalismaTurleri.Remi]: 1,
    [YerbisConstants.CalismaTurleri.Vlf]: 2,
    [YerbisConstants.CalismaTurleri.ManyetoTellurik]: 2,
    [YerbisConstants.CalismaTurleri.Tem]: 2,
    [YerbisConstants.CalismaTurleri.Hendek]: 1,
    [YerbisConstants.CalismaTurleri.YerRadari]: 3,
    [YerbisConstants.CalismaTurleri.Ert]: 1,
    [YerbisConstants.CalismaTurleri.Fay]: 3,
    [YerbisConstants.CalismaTurleri.PaleosismolojiCalismasi]: 3,
  };

  static CalismaTurleriColors = {
    [YerbisConstants.CalismaTurleri.None]: '#000000',
    [YerbisConstants.CalismaTurleri.Stabilite]: '#FF0000',
    [YerbisConstants.CalismaTurleri.Sondaj]: '#00FF00',
    [YerbisConstants.CalismaTurleri.ArastirmaCukuru]: '#0000FF',
    [YerbisConstants.CalismaTurleri.Des]: '#FFFF00',
    [YerbisConstants.CalismaTurleri.Ip]: '#FF00FF',
    [YerbisConstants.CalismaTurleri.Csamt]: '#00FFFF',
    [YerbisConstants.CalismaTurleri.Masw]: '#800000',
    [YerbisConstants.CalismaTurleri.MaswKirilma]: 'deeppink',
    [YerbisConstants.CalismaTurleri.Mikrotremor]: '#008080',
    [YerbisConstants.CalismaTurleri.SismikKirilma]: '#800080',
    [YerbisConstants.CalismaTurleri.SismikYansima]: '#FF8080',
    [YerbisConstants.CalismaTurleri.SismikTomografi]: '#80FF80',
    [YerbisConstants.CalismaTurleri.KuyuSismik]: '#8080FF',
    [YerbisConstants.CalismaTurleri.Gravite]: '#FF8000',
    [YerbisConstants.CalismaTurleri.Manyetik]: '#8000FF',
    [YerbisConstants.CalismaTurleri.Sp]: '#008000',
    [YerbisConstants.CalismaTurleri.GozlemNoktasi]: 'aqua',
    [YerbisConstants.CalismaTurleri.Remi]: '#FFFF80',
    [YerbisConstants.CalismaTurleri.Vlf]: '#FF80FF',
    [YerbisConstants.CalismaTurleri.ManyetoTellurik]: '#80FFFF',
    [YerbisConstants.CalismaTurleri.Tem]: '#FF0080',
    [YerbisConstants.CalismaTurleri.Hendek]: '#80FF00',
    [YerbisConstants.CalismaTurleri.YerRadari]: '#800080',
    [YerbisConstants.CalismaTurleri.Ert]: '#8080FF',
    [YerbisConstants.CalismaTurleri.PaleosismolojiCalismasi]: '#FF0000',
    [YerbisConstants.CalismaTurleri.Fay]: '#008080',
    [YerbisConstants.CalismaTurleri.YerlesimeUygunluk]: '#800000',
    [YerbisConstants.CalismaTurleri.Egim]: '#000080',
    [YerbisConstants.CalismaTurleri.JeolojiHaritasi]: '#FFFF00',
    [YerbisConstants.CalismaTurleri.MuhendislikHaritasi]: '#808000',
    [YerbisConstants.CalismaTurleri.CalismaAlaniGenislet]: '#FF8080',
  };

  static CalismaKategori = {
    Jeoloji: 1,
    Jeofizik: 2,
    Harita: 3,
  };

  static CalismaKategoriDesc = {
    [YerbisConstants.CalismaKategori.Jeoloji]: 'Jeoloji',
    [YerbisConstants.CalismaKategori.Jeofizik]: 'Jeofizik',
    [YerbisConstants.CalismaKategori.Harita]: 'Haritalar',
  };

  static CalismaAltKategori = {
    // Jeoloji Alt Kategorileri
    JeoteknikCalismasi: 1,
    PaleosismolojiCalismasi: 2,

    // Jeofizik Alt Kategorileri
    Sismik: 101,
    Mikrotremor: 102,
    Elektrik: 103,
    Elektromanyetik: 104,
    Manyetik: 105,
    Gravite: 106,

    // Harita Alt Kategorileri
    Harita: 201,
    ÇalismaAlani: 202,
  };

  static CalismaAltKategoriDesc = {
    // Jeoloji Alt Kategorileri
    [YerbisConstants.CalismaAltKategori.JeoteknikCalismasi]: 'Jeoteknik Çalışması',
    [YerbisConstants.CalismaAltKategori.PaleosismolojiCalismasi]: 'Paleosismoloji Çalışması',

    // Jeofizik Alt Kategorileri
    [YerbisConstants.CalismaAltKategori.Sismik]: 'Sismik',
    [YerbisConstants.CalismaAltKategori.Mikrotremor]: 'Mikrotremör',
    [YerbisConstants.CalismaAltKategori.Elektrik]: 'Elektrik',
    [YerbisConstants.CalismaAltKategori.Elektromanyetik]: 'Elektromanyetik',
    [YerbisConstants.CalismaAltKategori.Manyetik]: 'Manyetik',
    [YerbisConstants.CalismaAltKategori.Gravite]: 'Gravite',

    // Harita Alt Kategorileri
    [YerbisConstants.CalismaAltKategori.Harita]: 'Harita',
    [YerbisConstants.CalismaAltKategori.ÇalismaAlani]: 'Çalışma Alanı',
  };

  static ProjeEtudDurumlari = {
    Baslangic: 1001,
    Hazir: 1002,
    Kabul: 1003,
    Duzeltme: 1004,
    AraziTamamlandi: 1005,
    AraziOnay: 1006,
  };

  static ProjeEtudDurumlariDesc = {
    [YerbisConstants.ProjeEtudDurumlari.Baslangic]: 'Başlangıç',
    [YerbisConstants.ProjeEtudDurumlari.Hazir]: 'Hazır',
    [YerbisConstants.ProjeEtudDurumlari.Kabul]: 'Kabul',
    [YerbisConstants.ProjeEtudDurumlari.Duzeltme]: 'Düzeltme',
    [YerbisConstants.ProjeEtudDurumlari.AraziTamamlandi]: 'Arazi Tamamlandı',
    [YerbisConstants.ProjeEtudDurumlari.AraziOnay]: 'Arazi Onay',
  };

  static ProjePaftaTipi = {
    None: 0,
    ITRF: 1,
    ED50: 2,
  };

  static ProjePaftaTipiDesc = {
    [YerbisConstants.ProjePaftaTipi.None]: 'Tanımsız',
    [YerbisConstants.ProjePaftaTipi.ITRF]: 'ITRF',
    [YerbisConstants.ProjePaftaTipi.ED50]: 'ED50',
  };

  static ProjeListesiTipleri = {
    AktifProjelerim: 1,
    KomisyonProjeleri: 2,
    ArsivProjelerim: 3,
    TumProjeler: 4,
    IldenGelenProjeler: 5,
    BakanlikArsiv: 6,
    Arsiv2018Oncesi: 7,
    Arsiv2018Sonrasi: 8,
    MuhendisTalepleri: 9,
    Taslaklarim: 10,
  };

  static ProjeHarcBasvuruDurum = {
    OdemeBekliyor: 0,
    Odendi: 1,
    SuresindeIslemYapilmadigiIcinOdemeGecersiz: 2,
    SuresindeOdemeYapilmadigiIcinBasvuruIptalEdildi: 3,
  };

  static ProjeHarcBasvuruDurumDesc = {
    [YerbisConstants.ProjeHarcBasvuruDurum.OdemeBekliyor]: 'Ödeme Bekliyor',
    [YerbisConstants.ProjeHarcBasvuruDurum.Odendi]: 'Ödendi',
    [YerbisConstants.ProjeHarcBasvuruDurum.SuresindeIslemYapilmadigiIcinOdemeGecersiz]:
      'Süresinde İşlem Yapılmadığı İçin Ödeme Geçersiz Sayıldı',
    [YerbisConstants.ProjeHarcBasvuruDurum.SuresindeOdemeYapilmadigiIcinBasvuruIptalEdildi]:
      'Süresinde İşlem Yapılmadığı İçin Başvuru İptal Edildi',
  };

  static OlcuTeknigi = {
    TekKuyu: '1.2',
    KarsilikliIkiKuyu: '1.1',
  };

  static sondajMenuItems = {
    BILGI: 1,
    SONDAJ_BILGISI: 2,
    DERINLIK_DEGISIMI: 3,
    ZEMIN_KAYA_OZELLIKLERI: 4,
    DENEYLER: 5,
    ARAZI_DENEYLERI: 6,
    KAYA_BIRIMLERI: 7,
    KAYA_KALITESI: 8,
    AYRISMA: 9,
    DILATOMETRE: 10,
    BASINCLI_SU_DENEYI: 11,
    ZEMIN_BIRIMLERI: 12,
    PENETRASYON_DENEYLERI: 13,
    SPT: 14,
    CPT: 15,
    YUKLEME_DENEYLERI: 16,
    PLAKA_YUKLEME: 17,
    KANATLI_KESICI: 18,
    PRESIYOMETRE: 19,
    GECIRIMLILIK: 20,
    LABORATUVAR_DENEYLERI: 21,
    ZEMIN_LAB: 22,
    HACIM_AGIRLIK: 23,
    KIVAM_LIMITLERI: 24,
    ELEK_ANALIZI: 25,
    BIRLESMIS_ZEMIN_SINIFLAMASI: 26,
    ZEMIN_MEKANIGI_DENEYLERI: 27,
    UC_EKSENLI_KESME_DENEYI: 28,
    KESME_KUTUSU_DENEYI: 29,
    SERBEST_BASINC_DENEYI: 30,
    PROKTOR_DENEYI: 31,
    KALIFORNIYA_DENEYI: 32,
    HIDROMETRE_DENEYI: 33,
    SISME_BASINCI: 34,
    SISME_YUZDESI: 35,
    KONSOLIDASYON: 36,
    KAYA_LAB: 37,
    NOKTA_YUKLEME_DENEYI: 38,
    TEK_EKSENLI_BASINC_DENEYI: 39,
    UC_EKSENLI_BASINC_DENEYI: 40,
    HESAPLAMALAR: 41,
    TASIMA_HESAPLAMALARI: 42,
    ZEMIN_HESAPLAMALARI: 43,
    TERZAGHI: 44,
    KAYA_HESAPLAMALARI: 45,
    NOKTA_YUK: 46,
    TEK_EKSEN: 47,
    OTURMA_HESAPLAMALARI: 48,
    KONSOLIDASYON_OTURMA: 49,
    SPT_OTURMA: 50,
    SONDAJ_VE_KAROT_FOTO: 51,
    YORUM: 52,
    YAZDIR: 53,
    HACIM_AGIRLIK_2: 54,
    NUMUNE: 55,
  };

  static SondajAccordionItems = {
    PRESIYOMETRE: 'jeo_presiyometre',
    CPT: 'jeo_eler_sondaj_cpt',
    HACIM_AGIRLIK: 'jeo_eler_sondaj_hacim_agirlik',
    KIVAM_LIMITLERI: 'jeo_eler_sondaj_kivam',
    ELEK_ANALIZI: 'jeo_eler_sondaj_elek_analiz',
    UC_EKSENLI_KESME_DENEYI: 'jeo_ac_uc_eksenli_kesme',
    KESME_KUTUSU_DENEYI: 'jeo_eler_sondaj_kesme_kutusu',
    SERBEST_BASINC_DENEYI: 'jeo_eler_sondaj_serbest_basinc',
    NOKTA_YUKLEME_DENEYI: 'jeo_sondaj_kaya_nokta_yukleme',
    TEK_EKSENLI_BASINC_DENEYI: 'jeo_sondaj_tek_eksenli_basinc',
    UC_EKSENLI_BASINC_DENEYI: 'jeo_sondaj_uc_eksenli_basinc',
    PROKTOR_DENEYI: 'jeo_sondaj_proktor',
    HIDROMETRE_DENEYI: 'jeo_sondaj_hidrometre',
    KALIFORNIYA_DENEYI: 'jeo_sondaj_kaliforniya',
    SISME_BASINCI_DENEYI: 'jeo_sondaj_sisme_basinci',
    SISME_YUZDESI_DENEYI: 'jeo_sondaj_sisme_yuzdesi',
    KONSOLIDASYON: 'jeo_sondaj_konsolidasyon',
    PLAKA_YUKLEME_DENEYI: 'jeo_sondaj_plaka_yukleme',
    KANATLI_KESICI_DENEYI: 'jeo_sondaj_kanatli_kesici',
    GECIRIMLILIK: 'jeo_sondaj_gecirimlilik',
    DILATOMETRE: 'jeo_sondaj_dilatometre',
    BASINCLI_SU_DENEYI: 'jeo_sondaj_basincli_su',
    BIRLESTIRILMIS_ZEMIN_SINIFLAMASI: 'jeo_sondaj_zemin_sinifi',
  };

  static ArastirmaCukuruMenuItems = {
    BILGI: 1,
    DERINLIK: 2,
    ZEMIN_BILGISI: 3,
    NUMUNE: 4,
    ARALIK_BANDI: 5,
    LABORATUVAR_DENEYLERI: 6,
    ARAZI_FOTOGRAFLARI: 7,
    YORUM: 8,
    YAZDIR: 9,
    HACIM_AGIRLIK: 10,
    KIVAM_LIMITLERI: 11,
    ELEK_ANALIZI: 12,
    ZEMIN_MEKANIGI_DENEYLERI: 13,
    UC_EKSENLI_KESME_DENEYI: 14,
    KESME_KUTUSU_DENEYI: 15,
    SERBEST_BASINC_DENEYI: 16,
    PROKTOR_DENEYI: 17,
    KALIFORNIYA_DENEYI: 18,
    HIDROMETRE_DENEYI: 19,
    SISME_BASINCI: 20,
    SISME_YUZDESI: 21,
    KONSOLIDASYON: 22,
    NOKTA_YUKLEME_INDISI: 23,
    TEK_EKSENLI_BASINC_DENEYI: 24,
    UC_EKSENLI_BASINC_DENEYI: 25,
  };

  static ArastirmaCukuruAccordionItems = {
    HACIM_AGIRLIK: 'jeo_ac_hacim_agirlik',
    UC_EKSENLI_KESME_DENEYI: 'jeo_ac_uc_eksenli_kesme',
    KIVAM_LIMITLERI: 'jeo_ac_kivam_limitleri',
    ELEK_ANALIZI: 'jeo_ac_elek_analiz',
    KESME_KUTUSU_DENEYI: 'jeo_ac_kesme_kutusu',
    SERBEST_BASINC_DENEYI: 'jeo_ac_serbest_basinc',
    SISME_YUZDESI: 'jeo_ac_sisme_yuzdesi',
    PROKTOR_DENEYI: 'jeo_ac_proktor',
    KONSOLIDASYON: 'jeo_ac_konsolidasyon',
    NOKTA_YUKLEME_INDISI: 'jeo_ac_nokta_yukleme',
    KALIFORNIYA_DENEYI: 'jeo_ac_kaliforniya',
    HIDROMETRE_DENEYI: 'jeo_ac_hidrometre',
    SISME_BASINCI: 'jeo_ac_sisme_basinci',
    TEK_EKSENLI_BASINC_DENEYI: 'jeo_ac_tek_eksenli_basinc',
    UC_EKSENLI_BASINC_DENEYI: 'jeo_ac_uc_eksenli_basinc',
  };

  static RaporTurleri = {
    MIKROKAPAK: 44,
    MIKROBOLUM_I: 45,
    MIKROBOLUM_II: 46,
    MIKROBOLUM_III: 47,
    MIKROBOLUM_IV: 48,
    MIKROBOLUM_V: 49,
    MIKROBOLUM_VI: 50,
    MIKROBOLUM_VII: 51,
    MIKROBOLUM_VIII: 52,
    MIKROBOLUM_IX: 53,
    MIKROBOLUM_X: 54,
    MIKROBOLUM_XI: 55,
    MIKROBOLUM_XII: 56,
    MIKROBOLUM_XIII: 57,
    MIKROBOLUM_XIV: 58,
    MIKROBOLUM_XV: 59,
    MIKROBOLUM_XVI: 60,

    ARAZIKAPAK: 24,
    ARAZIBOLUM_I: 25,
    ARAZIBOLUM_II: 26,
    ARAZIBOLUM_III: 27,
    ARAZIBOLUM_IV: 28,
    ARAZIBOLUM_V: 29,
    ARAZIBOLUM_VI: 30,
    ARAZIBOLUM_VII: 31,
    ARAZIBOLUM_VIII: 32,
    ARAZIBOLUM_IX: 33,
    ARAZIBOLUM_X: 34,

    IMARPLANIKAPAK: 0,
    IMARPLANIBOLUM_I: 1,
    IMARPLANIBOLUM_II: 2,
    IMARPLANIBOLUM_III: 3,
    IMARPLANIBOLUM_IV: 4,
    IMARPLANIBOLUM_V: 5,
    IMARPLANIBOLUM_VI: 6,
    IMARPLANIBOLUM_VII: 7,
    IMARPLANIBOLUM_VIII: 8,
    IMARPLANIBOLUM_IX: 9,
    IMARPLANIBOLUM_X: 10,
    IMARPLANIBOLUM_XI: 11,
    IMARPLANIBOLUM_XII: 12,
    IMARPLANIBOLUM_XIII: 13,
    IMARPLANIBOLUM_XIV: 14,

    BILGIKARTI: 500,
    HAZIRLIKDILEKCE: 501,
    ONETUDDILEKCE: 502,
    ONAYSAYFASI: 503,

    EK_1: 150,
    EK_2: 160,
    EK_3: 170,
    EK_4: 180,
    EK_5: 190,
    EK_6: 200,
    EK_7: 210,
    EK_8: 220,
    EK_9: 230,

    TUM_RAPOR: 999,
  };

  static RaporVeriTurleri = {
    None: 0,
    DSIGorusu: 24,
    ZeminBuyutmeKonturHaritasi: 43,
    ZeminHakimTitreSimPeriyoduHaritasi: 69,
    TapuOrnegiVeKadastro: 103,
    BelediyeGorusu: 104,
    JeolojiHaritasi25000: 105,
    JeolojiHaritasi100000: 106,
    OzdirencKatHaritalari: 109,
    VS30Haritasi: 110,
    VSHaritasi: 111,
    LaboratuvarFoyleri: 112,
    PaleosismolojiRaporu: 116,
    SondajVeArastirmaCukuruLoglari: 118,
    BelediyeMeclisKarari: 122,
    Fotograflar: 123,
    KinematikAnalizler: 124,
    StabiliteAnalizi: 126,
    TasmanRaporu: 127,
    GeoteknikProje: 131,
    DigerGorusVeRaporlar: 132,
    GeoteknikDegerlendirmeRaporu: 136,
    CigEtuduRaporu: 137,
    TektonikGorus: 138,
    AfeteMaruzBelgeEkler: 139,
    Taahutnameler: 28,
    BolumBesBir25000OlcekliHarita: 10,
    BolumBesIki100000OlcekliHarita: 12,
    BolumBesUcJeolojiHaritasi: 114,
    BolumDokuzBirZeminKayaTurlerininSiniflandirilmasi: 29,
    BolumDokuzIkiMuhendislikZonlariVeZeminProfilleri: 30,
    BolumOnBirYerAltiSuyuDurumu: 98,
    BolumOnIkiYuzeySulari: 49,
    BolumOnUcIcmeVeKullanmaSuyu: 99,
    BolumOnIkiYerlesimeUygunlukDegerlendirmesi: 50,
    BolumOnIkiUygunAlanlarUA2: 115,
    BolumOnUcSonucOneriler: 51,
    BolumOnDortYararlanilanKaynaklar: 52,
    BolumUcBirBir: 2,
    BolumUcBirIki: 4,
    BolumUcBirUc: 16,
    BolumUcBirDort: 6,
    BolumUcBirBes: 7,
    BolumUcIkiBir: 8,
    BolumUcIkiIki: 53,
    BolumUcUcTaskinSahalari: 74,
    BolumUcDortDegisikAmacliEtutler: 75,
    BolumOnBirBirBir: 38,
    BolumOnBirBirIki: 39,
    BolumOnBirBirUc: 40,
    BolumOnBirBirDort: 42,
    BolumOnBirBirBes: 43,
    BolumOnBirKutleHareketleri: 44,
    BolumOnBirUcBir: 48,
    BolumOnBirUcIki: 25,
    BolumOnBirDortBir: 26,
    BolumOnBirDortIki: 27,
  };

  static MikrobolgelemeRaporVeriTurleri = {
    HaritalamaVeOlcek: 143,
    KarelajHucrelendirme: 144,
    BolumDortBirBir: 2,
    BolumDortBirIki: 4,
    BolumDortBirUc: 16,
    BolumDortBirDort: 6,
    BolumDortBirBes: 7,
    BolumDortIkiBir: 8,
    BolumDortIkiIki: 53,
    BolumDortUcTaskinSahalari: 74,
    BolumOnUcBirBir: 38,
    BolumOnUcAktifTektonik: 39,
    BolumOnUcPaleosismolojikCalismalar: 40,
    BolumOnUcSivilastirmaAnalizi: 42,
    BolumOnUcZeminBuyutmeveHakimPeriyodunBelirlenmesi: 43,
    BolumOnUcKutleHareketleri: 44,
    BolumOnUcHeyelan: 137,
    BolumOnUcKayaDusmesi: 138,
    BolumOnUcSuBaskini: 48,
    BolumOnUcCig: 25,
    BolumOnDortYerlesimeUygunluk: 50,
    BolumOnBesSonucOneriler: 51,
    BolumOnBesKaynaklar: 52,
  };

  static RaporVeriTurleriDesc = {
    [YerbisConstants.RaporVeriTurleri.None]: 'Belirsiz',
    [YerbisConstants.RaporVeriTurleri.DSIGorusu]: 'DSİ görüşü',
    [YerbisConstants.RaporVeriTurleri.ZeminBuyutmeKonturHaritasi]: 'Zemin Büyütme Kontur Haritası',
    [YerbisConstants.RaporVeriTurleri.ZeminHakimTitreSimPeriyoduHaritasi]: 'Zemin Hakim Titreşim Periyodu Haritası',
    [YerbisConstants.RaporVeriTurleri.TapuOrnegiVeKadastro]: 'Tapu Örneği ve Kadastro',
    [YerbisConstants.RaporVeriTurleri.BelediyeGorusu]: 'Belediye Görüşü',
    [YerbisConstants.RaporVeriTurleri.JeolojiHaritasi25000]: '1/25000 Jeoloji Haritasi',
    [YerbisConstants.RaporVeriTurleri.JeolojiHaritasi100000]: '1/100000 Jeoloji Haritasi',
    [YerbisConstants.RaporVeriTurleri.OzdirencKatHaritalari]: 'Ozdirenc Kat Haritalari',
    [YerbisConstants.RaporVeriTurleri.VS30Haritasi]: 'VS30 Haritasi',
    [YerbisConstants.RaporVeriTurleri.VSHaritasi]: 'VS Haritasi',
    [YerbisConstants.RaporVeriTurleri.LaboratuvarFoyleri]: 'Labaratuvar Föyleri',
    [YerbisConstants.RaporVeriTurleri.PaleosismolojiRaporu]: 'Paleosismoloji Raporu',
    [YerbisConstants.RaporVeriTurleri.SondajVeArastirmaCukuruLoglari]: 'Sondaj ve araştırma çukuru logları',
    [YerbisConstants.RaporVeriTurleri.BelediyeMeclisKarari]: 'Belediye Meclis Kararı',
    [YerbisConstants.RaporVeriTurleri.Fotograflar]: 'Fotoğraflar',
    [YerbisConstants.RaporVeriTurleri.KinematikAnalizler]: 'Kinematik Analizler',
    [YerbisConstants.RaporVeriTurleri.StabiliteAnalizi]: 'Stabilite Analizi',
    [YerbisConstants.RaporVeriTurleri.TasmanRaporu]: 'Tasman Raporu',
    [YerbisConstants.RaporVeriTurleri.GeoteknikProje]: 'Geoteknik Proje',
    [YerbisConstants.RaporVeriTurleri.DigerGorusVeRaporlar]: 'Diğer Görüş Ve Raporlar',
    [YerbisConstants.RaporVeriTurleri.GeoteknikDegerlendirmeRaporu]: 'Geoteknik Değerlendirme Raporu',
    [YerbisConstants.RaporVeriTurleri.CigEtuduRaporu]: 'Çığ Etüdü Raporu',
    [YerbisConstants.RaporVeriTurleri.TektonikGorus]: 'Tektonik Görüş',
    [YerbisConstants.RaporVeriTurleri.AfeteMaruzBelgeEkler]: 'Afete Maruz Belge Ekler',
  };

  static CIHAZ_MODEL_TURLERI = {
    MASW: 635,
    MASW_KIRILMA: 636,
    SISMIK_KIRILMA: 637,
    SISMIK_YANSIMA: 638,
    REMI: 639,
    KUYU_SISMIGI: 640,
    SISMIK_TOMOGRAFI: 641,
    MIKROTREMOR: 642,
    ERT: 643,
    DES: 644,
    IP: 645,
    SP: 646,
    YER_RADARI_GPR: 647,
    MANYETO_TELLURIK: 648,
    VLF: 649,
    TEM: 650,
    CSAMT: 651,
    MANYETIK: 652,
    GRAVITE: 653,
  };

  static DagitimZorunlu = {
    MEKANSAL_PLANLAMA: '52942367',
    ACIL_AFET: '56388857',
  };

  static EtutAmacTurleri = {
    None: 0,
    EvveliyatiAyrintiliJeoteknikEtutGerektirenAlan: 1,
    IlIlceBeldeButunundeCalisma: 2,
    IlkCalisma: 3,
    MikroBolgeleme: 4,
    EvveliyatiUygunOlmayanAlan: 5,
    UstOlcekPlan: 6,
    KorumaAmacli: 518,
  };

  static NodeIds = {
    Bakanlik: 1619,
  };
}

export default YerbisConstants;
