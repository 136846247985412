import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UisKeySkeleton from '@iconscout/react-unicons/icons/uil-key-skeleton';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { useAuth } from '../../../hooks/useAuth';

import { KURUM, FIRMA, MUHENDIS, ADMIN as ADMINTYPE } from '../../../constants/typeConstant';
import { DataService } from '../../../config/dataService/dataService_yerbis';
import NotificationHelper from '../../../utility/notificationHelper';

const AuthInfo = React.memo(() => {
  const { user, logout } = useAuth();
  const [muhendisData, setMuhendisData] = useState({});

  const getMuhendisData = async () => {
    try {
      const response = await DataService.post(`${process.env.REACT_APP_MUHENDIS_GET_ENDPOINT}`, {
        muhendisId: user.muhendisIdHash,
      });
      setMuhendisData({ ...response.data.model[0] });
    } catch (err) {
      NotificationHelper.setErrorNotification();
    } finally {
      //
    }
  };

  useEffect(() => {
    switch (user.type) {
      case KURUM:
        break;
      case FIRMA:
        break;
      case MUHENDIS:
        getMuhendisData();
        break;
      case ADMINTYPE:
        break;
      default:
        break;
    }
  }, []);

  console.log(user);

  let profileUrl = '';
  switch (user.type) {
    case KURUM:
      profileUrl = `/admin/BilgiSayfalari/KurumBilgileri/KurumDetay/${user.organizationIdStr}`;
      break;
    case FIRMA:
      profileUrl = `/admin/BilgiSayfalari/FirmaBilgileri/FirmaDetay/${user.organizationIdStr}`;
      break;
    case MUHENDIS:
      profileUrl = `/admin/BilgiSayfalari/MuhendisBilgileri/MuhendisDetay/${user.muhendisIdHash}`;
      break;
    case ADMINTYPE:
      profileUrl = `/admin/BilgiSayfalari/MuhendisBilgileri/MuhendisDetay/${user.muhendisIdHash}`;
      break;
    default:
      break;
  }

  let changePasswordUrl = '';
  switch (user.type) {
    case KURUM:
      changePasswordUrl = `/admin/BilgiSayfalari/KurumBilgileri/SifreDegistir/${user.organizationIdStr}`;
      break;
    case FIRMA:
      changePasswordUrl = `/admin/BilgiSayfalari/FirmaBilgileri/SifreDegistir/${user.organizationIdStr}`;
      break;
    case MUHENDIS:
      changePasswordUrl = `/admin/BilgiSayfalari/MuhendisBilgileri/SifreDegistir/${user.muhendisIdHash}`;
      break;
    case ADMINTYPE:
      changePasswordUrl = `/admin/BilgiSayfalari/MuhendisBilgileri/SifreDegistir/${user.muhendisIdHash}`;
      break;
    default:
      break;
  }

  const [state, setState] = useState({
    flag: 'tr',
  });
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();
    logout(() => navigate('/'));
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info align-items-center">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            {user.type === 2 ? (
              <Heading as="h5">
                {muhendisData?.Ad} {muhendisData?.Soyad}
              </Heading>
            ) : (
              <Heading as="h5">
                {user.name} {user.surname}
              </Heading>
            )}
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={profileUrl}>
              <UilUser /> {t('info.profile')}
            </Link>
          </li>
          <li>
            <Link to={changePasswordUrl}>
              <UisKeySkeleton /> {t('info.changePassword')}
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> {t('info.signOut')}
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const country = (
    <NavAuth>
      <Link onClick={(e) => onFlagChangeHandle('tr', e)} to="#">
        <img src={require('../../../static/img/flag/tr.png')} alt="" />
        <span>Türkçe</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('en', e)} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" />
        <span>English</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Search />
      <Message />
      <Notification />
      <Settings /> */}
      {/* <Can perform={[KAMU_MUHENDISI]}>
        <div className="ninjadash-nav-actions__item ninjadash-nav-actions__language">
          <Dropdown placement="bottomRight" content={country} trigger="click">
            <Link to="#" className="ninjadash-nav-action-link">
              <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
            </Link>
          </Dropdown>
        </div>
      </Can> */}
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar
              style={{ backgroundColor: 'aliceblue' }}
              icon={<UilUser />}
              // src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"
            />
            <span className="ninjadash-nav-actions__author--name">
              {user.name} {user.surname}
            </span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
