import actions from './actions';

const { UPDATE_FILTERS, READ_BEGIN, READ_SUCCESS, READ_ERR, RUN_TRIGGER } = actions;

const initialStateFilter = {
  data: [],
  loading: false,
  error: null,
  filters: {},
  trigger: undefined,
};

const Reducer = (state = initialStateFilter, action) => {
  const { type, data, err, filters } = action;
  switch (type) {
    case UPDATE_FILTERS:
      return {
        ...state,
        filters,
      };
    case READ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };
    case READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case RUN_TRIGGER:
      return {
        ...state,
        trigger: !state.trigger,
      };
    default:
      return state;
  }
};

export { Reducer };
