import { getNavigate } from './navigateHandler';

const createErrorHandling = () => {
  const fetchErrorHandling = async (request) => {
    const navigate = getNavigate();
    try {
      const response = await request();
      if (response.status !== 200) {
        navigate('/Error');
      }
      return response;
    } catch (error) {
      navigate('/Error');
      throw error;
    }
  };

  return fetchErrorHandling;
};

export default createErrorHandling;
