const actions = {
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  READ_BEGIN: 'READ_BEGIN',
  READ_SUCCESS: 'READ_SUCCESS',
  READ_ERR: 'READ_ERR',
  RUN_TRIGGER: 'RUN_TRIGGER',

  updateFilters: (filters) => {
    return {
      type: actions.UPDATE_FILTERS,
      filters,
    };
  },

  ReadBegin: () => {
    return {
      type: actions.READ_BEGIN,
    };
  },

  ReadSuccess: (data) => {
    return {
      type: actions.READ_SUCCESS,
      data,
    };
  },

  ReadErr: (err) => {
    return {
      type: actions.READ_ERR,
      err,
    };
  },

  RunTrigger: () => {
    return {
      type: actions.RUN_TRIGGER,
    };
  },
};

export default actions;
