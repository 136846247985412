let navigate = null;

const setNavigate = (nav) => {
  navigate = nav;
};

const getNavigate = () => navigate;

const createBasePath = (path) => {
  // PUBLIC_URL'den path oluştur
  const basePath = process.env.PUBLIC_URL || '';
  return `${basePath}${path}`;
};

export { setNavigate, getNavigate, createBasePath };
