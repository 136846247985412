import axios from 'axios';
import FormData from 'form-data';
import { getItem } from '../../utility/localStorageControl';
import createErrorHandling from '../../utility/createErrorHandling ';

const API_ENDPOINT = `${process.env.REACT_APP_YERBIS_TEST_APP_API_ENDPOINT}`;

const contentType = (useJSONInBody = false) => ({
  'Content-Type': useJSONInBody ? 'application/json' : 'multipart/form-data',
});

const client = axios.create({
  baseURL: API_ENDPOINT,
});

const fetchErrorHandling = createErrorHandling();

class DataService {
  static async get(path = '', params = {}, useJSONInBody = true) {
    return fetchErrorHandling(() =>
      client({
        method: 'GET',
        url: path,
        params: useJSONInBody ? params : this.getFormData(params),
        headers: { ...contentType(useJSONInBody) },
      }),
    );
  }

  static async post(path = '', data = {}, optionalHeader = {}, useJSONInBody = false) {
    return fetchErrorHandling(() =>
      client({
        method: 'POST',
        url: path,
        data: useJSONInBody ? data : this.getFormData(data),
        headers: { ...optionalHeader, ...contentType(useJSONInBody) },
      }),
    );
  }

  static async delete(path = '', data = {}, optionalHeader = {}, useJSONInBody = false) {
    return fetchErrorHandling(() =>
      client({
        method: 'DELETE',
        url: path,
        data: useJSONInBody ? data : this.getFormData(data),
        headers: { ...optionalHeader, ...contentType(useJSONInBody) },
      }),
    );
  }

  static async patch(path = '', data = {}, useJSONInBody = false) {
    return fetchErrorHandling(() =>
      client({
        method: 'PATCH',
        url: path,
        data: useJSONInBody ? data : this.getFormData(data),
        headers: { ...contentType(useJSONInBody) },
      }),
    );
  }

  static async put(path = '', data = {}, useJSONInBody = false) {
    return fetchErrorHandling(() =>
      client({
        method: 'PUT',
        url: path,
        data: useJSONInBody ? data : this.getFormData(data),
        headers: { ...contentType(useJSONInBody) },
      }),
    );
  }

  static async getBlob(path = '', data = {}, optionalHeader = {}, useJSONInBody = false) {
    return fetchErrorHandling(() =>
      client({
        method: 'POST',
        url: path,
        data: useJSONInBody ? data : this.getFormData(data),
        headers: { ...optionalHeader, ...contentType(useJSONInBody) },
        responseType: 'blob',
      }),
    );
  }

  static async NonAuthPostWithFormData(path = '', data = {}) {
    const formData = new FormData();
    let response;

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    try {
      response = await fetchErrorHandling(() =>
        axios.post(path, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static async NonAuthGet(path = '') {
    const response = await fetchErrorHandling(() => axios.get(path));
    return response;
  }

  static getFormData(data = {}) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return formData;
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  const accessToken = getItem('access_token');
  requestConfig.headers = { ...headers, token: accessToken ? `${accessToken}` : '' };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
      } else if (response.status === 400) {
        return response;
      } else {
        return originalRequest;
      }
    }
    return Promise.reject(error);
  },
);
export { DataService };
