import { useSelector, useDispatch } from 'react-redux';
import { login, logOut } from '../redux/authentication/actionCreator';
import { selectUser, isAuthenticated, hasRole, hasRoles, hasTypes, hasType } from '../redux/authentication/reducers';

export const useAuth = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const isUserAuthenticated = useSelector(isAuthenticated);

  const loginUser = (values, callback) => {
    dispatch(login(values, callback));
  };

  const logoutUser = (callback) => {
    dispatch(logOut(callback));
  };

  const checkRole = (role) => {
    return useSelector(hasRole(role));
  };

  const checkRoles = (roles) => {
    return useSelector(hasRoles(roles));
  };

  const checkUserType = (type) => {
    return useSelector(hasType(type));
  };

  const checkUserTypes = (types) => {
    return useSelector(hasTypes(types));
  };

  return {
    user,
    login: loginUser,
    logout: logoutUser,
    isAuthenticated: isUserAuthenticated,
    hasRole: checkRole,
    hasRoles: checkRoles,
    hasType: checkUserType,
    hasTypes: checkUserTypes,
  };
};
