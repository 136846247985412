const actions = {
  LIST_DATA_READ_BEGIN: 'LIST_DATA_READ_BEGIN',
  LIST_DATA_READ_SUCCESS: 'LIST_DATA_READ_SUCCESS',
  LIST_DATA_READ_ERR: 'LIST_DATA_READ_ERR',

  LIST_UPDATE_PAGINATION: 'LIST_UPDATE_PAGINATION',

  LIST_UPDATE_FILTERS: 'LIST_UPDATE_FILTERS',
  LIST_UPDATE_FILTER: 'LIST_UPDATE_FILTER',

  LIST_UPDATE_DATA: 'LIST_UPDATE_DATA',

  updateData: (val) => {
    return {
      type: actions.LIST_UPDATE_DATA,
      updateData: val,
    };
  },

  updateFilters: (filters) => {
    return {
      type: actions.LIST_UPDATE_FILTERS,
      filters,
    };
  },

  updateFilter: (filter) => {
    return {
      type: actions.LIST_UPDATE_FILTER,
      filter,
    };
  },

  updatePagination: (pagination) => {
    return {
      type: actions.LIST_UPDATE_PAGINATION,
      pagination,
    };
  },

  ReadBegin: () => {
    return {
      type: actions.LIST_DATA_READ_BEGIN,
    };
  },

  ReadSuccess: (data) => {
    return {
      type: actions.LIST_DATA_READ_SUCCESS,
      data,
    };
  },

  ReadErr: (err) => {
    return {
      type: actions.LIST_DATA_READ_ERR,
      err,
    };
  },
};

export default actions;
