import React, { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ProjeLayout from '../../container/proje/Index';
// import { setNavigate } from '../../utility/navigateHandler';

// const NotFound = lazy(() => import('../container/pages/404'));
const ProjeDetay = lazy(() => import('../../container/proje/ProjeDetay'));

const ProjeRoot = () => {
  const navigate = useNavigate();

  <div> </div>;

  useEffect(() => navigate('/'));
};

const FrontendRoutes = React.memo(() => {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   setNavigate(navigate);
  // }, [navigate]);

  return (
    <Routes>
      <Route path="detay/:id/*" element={<ProjeDetay />} />
      {/* <Route index element={<Login />} />
      <Route path="forgotPassword" element={<ForgotPass />} />
      <Route path="register" element={<SignUp />} />
      <Route path="fbRegister" element={<FbSignUp />} />
      <Route path="fbSignIn" element={<FbLogin />} /> */}
      {/* <Route path="*" element={<NotFound />} /> */}
      <Route path="*" element={<ProjeRoot />} />
    </Routes>
  );
});

export default ProjeLayout(FrontendRoutes);
