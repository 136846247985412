import actions from './actions';

const {
  LIST_DATA_READ_BEGIN,
  LIST_DATA_READ_SUCCESS,
  LIST_DATA_READ_ERR,

  LIST_UPDATE_PAGINATION,

  LIST_UPDATE_FILTERS,
  LIST_UPDATE_FILTER,
  LIST_UPDATE_DATA,
} = actions;

const initialState = {
  data: [],
  loading: false,
  error: null,
  pagination: {
    current: 1,
    pageSize: 10,
  },
  filters: {},
  updateData: false,
};

const Reducer = (state = initialState, action) => {
  const { type, data, err, pagination, filter, filters, updateData } = action;

  switch (type) {
    case LIST_DATA_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LIST_DATA_READ_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case LIST_DATA_READ_ERR:
      return {
        ...state,
        err,
      };
    case LIST_UPDATE_PAGINATION:
      return {
        ...state,
        pagination,
      };
    case LIST_UPDATE_FILTERS:
      return {
        ...state,
        filters,
      };
    case LIST_UPDATE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...filter },
      };
    case LIST_UPDATE_DATA:
      return {
        ...state,
        updateData,
      };

    default:
      return state;
  }
};

export { Reducer };
